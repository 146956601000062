import React, { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, Typography } from '@mui/material';

import style from './Multiple.module.scss';
import { IQuestion } from '../../../types/data';
import { useTranslation } from 'react-i18next';

export interface MultipleProps extends PropsWithChildren {
    question: IQuestion;
    answer?: string[];
    callBack: (answer: string[]) => void;
}

interface IQuestionMultipleForm {
    answer: string[];
}

const Multiple: React.FC<MultipleProps> = ({ question, answer, callBack, children }) => {
    const { t } = useTranslation();

    const formMultipleSchema = yup.object().shape({
        answer: yup.array().min(1),
    });

    const {
        register: registerMultiple,
        handleSubmit: handleSubmitMultiple,
        formState: { errors: errorsMultiple },
        reset: resetMultiple,
    } = useForm<IQuestionMultipleForm>({
        defaultValues: { answer },
        resolver: yupResolver(formMultipleSchema),
    });

    const onSubmitMultipleHandler = handleSubmitMultiple((formData) => {
        callBack(formData.answer);
    });

    React.useEffect(() => {
        resetMultiple({ answer });
    }, [question]);

    return (
        <form onSubmit={onSubmitMultipleHandler} className={style.form}>
            <FormGroup>
                {question.choices
                    .filter((choice) => choice.text.length > 0)
                    .map((choice, key) => (
                        <label key={key} className={style.label}>
                            <input type="checkbox" {...registerMultiple('answer')} value={choice.weight} />
                            <Typography className={style.labelText}>{choice.text}</Typography>
                        </label>
                    ))}
                <Typography color="error">{errorsMultiple.answer && t('error.oneAnswerNeeded', { ns: 'common' })}</Typography>
            </FormGroup>

            {children}
        </form>
    );
};

export default Multiple;
