import React from 'react';
import { Box } from '@mui/system';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_WELCOME } from '../../utils/welcome';
import LocalSwitcher from '../localSwitcher/LocalSwitcher';
import { Link as RouteLink } from 'react-router-dom';

import style from './Header.module.scss';
import { Typography, useMediaQuery } from '@mui/material';
import { filterQuestionsByRole, getUserRoles } from '../../utils/nextPrevFlow';
import { useTheme } from '@emotion/react';

export interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { sectionSlug, questionId } = useCustomUseParams();

    const currentSection = globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug);

    const userRoles = getUserRoles(globalEnvReducer);

    // if data company is set then we have 3 questions in welcome section else 2 questions
    const totalQuestions = sectionSlug === SECTION_WELCOME ? (globalEnvReducer.data?.meta.company ? 3 : 2) : filterQuestionsByRole(userRoles ? userRoles : [], currentSection)?.length;

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box className={style.container}>
            <RouteLink to={`/`} className={`${style.homeCTA} ${underThanMid ? style.mobile : ''}`}>
                <Typography fontFamily="VT323">{globalEnvReducer?.data?.meta?.mainTitle}</Typography>
                {process.env.REACT_APP_DEBUG_SHOW_USER_ID === 'true' ? `USER ID: ${globalEnvReducer.userId}` : ''}
            </RouteLink>
            <Typography fontFamily="VT323" className={style.questions}>
                {currentSection && sectionSlug ? currentSection.name : ''}
                {underThanMid ? <br /> : ' '}
                {currentSection && sectionSlug && questionId !== undefined ? `${questionId} / ${totalQuestions}` : ''}
            </Typography>
            <LocalSwitcher />
        </Box>
    );
};

export default Header;
