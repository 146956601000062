import { Box } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../hooks/storesHooks';
import { filterQuestionsByRole, getUserRoles } from '../../utils/nextPrevFlow';
import { SECTION_WELCOME } from '../../utils/welcome';
import { getAnswerFilteredByVersion } from '../../utils/checkAnswers';
import ResultView from '../../components/result/ResultView';
import { useTranslation } from 'react-i18next';

import style from './Result.module.scss';
import { ISectionResult } from '../../types/companyResults';

export interface IResultProps {}

const Result: React.FC<IResultProps> = () => {
    const { t } = useTranslation();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const userRoles = getUserRoles(globalEnvReducer);

    const filterQuestions = globalEnvReducer.data?.sections.filter((section) => filterQuestionsByRole(userRoles ? userRoles : [], section)?.length > 0 && section.slug !== SECTION_WELCOME);
    const scorePerSection = [];
    let totalScore = 0;

    for (let i = 0; filterQuestions && i < filterQuestions.length; i++) {
        const section = filterQuestions[i];
        let value = 0;
        let totalAnwserPoint = 0;
        const userSection = globalEnvReducer.user?.sections?.find((userSection) => userSection.slug === section.slug);
        const dataSection = globalEnvReducer.data?.sections?.find((dataSection) => dataSection.slug === section.slug);

        userSection?.answersArray
            ?.filter((answersVersion) => {
                const answer = getAnswerFilteredByVersion(answersVersion, globalEnvReducer);
                return answer && answer.answers.length > 0;
            })
            .forEach((answersVersion, key) => {
                const answer = getAnswerFilteredByVersion(answersVersion, globalEnvReducer);
                if (!answer) {
                    return;
                }

                for (let i = 0; i < answer.answers.length; i++) {
                    const answerValue = parseInt(answer.answers[i]);
                    // get the weight of the answer
                    value += dataSection?.questions[key].choices[answerValue]?.weight || 0;
                }

                totalAnwserPoint += dataSection?.questions[key].choices[dataSection?.questions[key].choices.length - 1]?.weight || 0;
            });

        const valuePercent = Math.round((value * 100) / totalAnwserPoint);

        scorePerSection.push({ slug: section.slug, score: valuePercent } as ISectionResult);
        totalScore += valuePercent;
    }

    return (
        <Box className={style.container}>
            <ResultView title={t('page.result.titleLeft', { ns: 'common' })} totalScore={totalScore} scorePerSection={scorePerSection} />
        </Box>
    );
};

export default Result;
