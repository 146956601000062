import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_RESULT } from '../../utils/welcome';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import style from './Informations.module.scss';
import { useUpdateUserMutation } from '../../services/user';
import { IInformations, IUser } from '../../types/api';
import { errorReload } from '../../utils/reload';

export interface IInformationsProps {}

const Informations: React.FC<IInformationsProps> = () => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [updateUser] = useUpdateUserMutation();

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneError = t('page.informations.phoneError', { ns: 'common' });
    /* eslint-disable */
    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const emailError = t('page.informations.emailError', { ns: 'common' });

    const formSchema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        phone: yup.string().matches(phoneRegExp, phoneError),
        email: yup.string().matches(emailRegExp, emailError),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<IInformations>({
        defaultValues: {
            firstname: globalEnvReducer.user?.informations?.firstname ? globalEnvReducer.user?.informations?.firstname : '',
            lastname: globalEnvReducer.user?.informations?.firstname ? globalEnvReducer.user?.informations?.lastname : '',
            phone: globalEnvReducer.user?.informations?.firstname ? globalEnvReducer.user?.informations?.phone : '',
            email: globalEnvReducer.user?.informations?.firstname ? globalEnvReducer.user?.informations?.email : '',
        },
        resolver: yupResolver(formSchema),
    });

    const onSubmitHandler = handleSubmit((formData) => {
        if (globalEnvReducer.user && globalEnvReducer.userId) {
            const userClone: IUser = { ...globalEnvReducer.user };

            userClone.informations = { ...formData };

            updateUser({ userId: globalEnvReducer.userId, updateParam: userClone }).then((res) => {
                const redirect = () => {
                    if (globalEnvReducer.data?.meta?.showResults === false) {
                        navigate(`/`);
                    } else {
                        navigate(`/${SECTION_RESULT}`);
                    }
                };

                if ('data' in res) {
                    // @ts-ignore
                    if (res.data.error) {
                        // @ts-ignore
                        if (res.data.status === 404) {
                            errorReload(t('error.404', { ns: 'common' }));
                        } else {
                            errorReload(t('error.async', { ns: 'common' }));
                        }
                        return;
                    } else {
                        redirect();
                        return;
                    }
                } else {
                    errorReload(t('error.async', { ns: 'common' }));
                    return;
                }
            });
        }
    });

    return (
        <Box className={style.container}>
            <Typography variant="h2" className={style.title}>
                {t('page.informations.title', { ns: 'common' })}
            </Typography>

            <form onSubmit={onSubmitHandler} className={style.form}>
                <TextField label={t('page.informations.firstname')} {...register('firstname')} error={errors.firstname ? true : false} helperText={errors.firstname ? errors.firstname.message : ''} />
                <TextField label={t('page.informations.lastname')} {...register('lastname')} error={errors.lastname ? true : false} helperText={errors.lastname ? errors.lastname.message : ''} />

                <TextField label={t('page.informations.email')} {...register('email')} error={errors.lastname ? true : false} helperText={errors.email ? errors.email.message : ''} />

                <TextField label={t('page.informations.phone')} {...register('phone')} error={errors.lastname ? true : false} helperText={errors.phone ? errors.phone.message : ''} />

                <Box className={style.ctaContainer}>
                    <Button onClick={() => navigate(`/${SECTION_RESULT}`)} color="secondary">
                        {t('skip', { ns: 'common' })}
                    </Button>
                    <Button type="submit" variant="contained" className={style.submitCTA}>
                        {t(globalEnvReducer.data?.meta?.showResults === false ? 'page.informations.resultCTA' : 'page.informations.sendCTA', { ns: 'common' })}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default Informations;
