import React from 'react';
import { Box, Drawer, IconButton, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import Circular from '../progress/circular/Circular';
import { AiOutlineClose } from 'react-icons/ai';
import { useTheme } from '@emotion/react';
import Linear from '../progress/linear/Linear';
import { useTranslation } from 'react-i18next';

import style from './ResultView.module.scss';
import { BsFillTriangleFill } from 'react-icons/bs';
import { ISectionResult } from '../../types/companyResults';

export interface ICompareScores {
    name: string;
    totalScore: number;
    scorePerSection: Array<ISectionResult>;
}

export interface IResultProps {
    title: string;
    totalUsersCompleted?: number;
    totalScore: number;
    scorePerSection: Array<ISectionResult>;
    compareScores?: Array<ICompareScores>;
}

const ResultView: React.FC<IResultProps> = ({ title, totalUsersCompleted, totalScore, scorePerSection, compareScores }) => {
    const [openDetails, setOpenDetails] = React.useState(false);
    const { t } = useTranslation();

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    const scorePerSectionGenerator = (scorePerSection: Array<ISectionResult>) => {
        return (
            <>
                {scorePerSection.map((result, index) => (
                    <Linear key={index} value={result.score} label={result.slug} compareScores={compareScores} slug={result.slug} verbatim={result.verbatim} minScore={result.minScore} maxScore={result.maxScore} />
                ))}
                {compareScores && (
                    <List>
                        {compareScores.map((compareScore, key) => {
                            return (
                                <ListItem key={key} className={`${style[`color${key > 6 ? Math.round(key / 6) : key}`]} ${style[`color${key > 6 ? Math.round(key / 6) : key}`]}`}>
                                    <BsFillTriangleFill />{' '}
                                    <Typography fontSize={14} className={style[`color${key > 6 ? Math.round(key / 6) : key}`]}>
                                        {compareScore.name}
                                    </Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </>
        );
    };

    return (
        <Box className={style.container}>
            <Box className={`${style.leftContainer} ${totalUsersCompleted ? '' : style.fullHeight} ${underThanMid ? style.full : ''}`}>
                <Typography variant="h2">{title}</Typography>

                <Box className={`${style.result} ${underThanMid ? style.clickable : ''}`} onClick={() => setOpenDetails(true)}>
                    <Circular value={Math.round(totalScore / scorePerSection.length)} showIconDetails={underThanMid} size={300} compareScores={compareScores} />

                    {compareScores && (
                        <List>
                            {compareScores.map((compareScore, key) => {
                                return (
                                    <ListItem key={key} className={`${style[`color${key > 6 ? Math.round(key / 6) : key}`]} ${style[`color${key > 6 ? Math.round(key / 6) : key}`]}`}>
                                        <BsFillTriangleFill />{' '}
                                        <Typography fontSize={14} className={style[`color${key > 6 ? Math.round(key / 6) : key}`]}>
                                            {' '}
                                            {compareScore.name} : {compareScore.totalScore}
                                        </Typography>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </Box>

                {totalUsersCompleted && <Typography className={style.totalUsersCompleted}>{t('page.companyResults.percentCompleted', { ns: 'common', value: totalUsersCompleted })}</Typography>}
            </Box>
            {underThanMid ? (
                <Drawer anchor="right" open={openDetails} onClose={() => setOpenDetails(false)}>
                    <Box className={`${style.rightContainer} ${totalUsersCompleted ? '' : style.fullHeight}`}>
                        <IconButton className={style.closeCTA} onClick={() => setOpenDetails(false)}>
                            <AiOutlineClose />
                        </IconButton>

                        <Typography variant="h2">{t('page.result.titleRight', { ns: 'common' })}</Typography>

                        {scorePerSectionGenerator(scorePerSection)}
                    </Box>
                </Drawer>
            ) : (
                <Box className={`${style.rightContainer} ${totalUsersCompleted ? '' : style.fullHeight}`}>
                    <Typography variant="h2">{t('page.result.titleRight', { ns: 'common' })}</Typography>

                    {scorePerSectionGenerator(scorePerSection)}
                </Box>
            )}
        </Box>
    );
};

export default ResultView;
