export interface ISection {
    name: string;
    slug: string;
    description?: string;
    questions: IQuestion[];
}

export interface IData {
    meta: {
        id: string;
        version: string;
        main_title: string;
        intro: string;
        mainTitle: string;
        showResults: boolean;
        languages: string[];
        logos: string[];
        company: {
            logo: string;
            name: string;
            size: number;
            revenue: string;
            date: string;
        };
    };

    sections: ISection[];
}

export enum EQuestionType {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
    OPEN = 'open',
    MATRIX = 'matrix',
}

export interface IChoice {
    text: string;
    weight: number;
}

export interface IExample {
    text: string;
    scope: number[];
}
export interface IQuestion {
    type: EQuestionType;
    text: string;
    theme?: string;
    choices: IChoice[];
    examples: IExample[];
    scope: number[];
    subquestions?: string[];
}
