import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCustomUseParams } from '../../../hooks/customUseParamsHooks';
import { useAppSelector } from '../../../hooks/storesHooks';
import { previousQuestions } from '../../../utils/nextPrevFlow';

export interface IPrevCtaProps {
    disabled?: boolean;
}

const PrevCta: React.FC<IPrevCtaProps> = ({ disabled }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { sectionSlug, questionId } = useCustomUseParams();

    const handlePrev = () => {
        previousQuestions(sectionSlug, questionId, globalEnvReducer, navigate);
    };

    return (
        <Button onClick={handlePrev} color="secondary" disabled={disabled}>
            {t('previous', { ns: 'common' })}
        </Button>
    );
};

export default PrevCta;
