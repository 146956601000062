import { Collapse, IconButton, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft, BsFillTriangleFill } from 'react-icons/bs';

import style from './Linear.module.scss';
import { ICompareScores } from '../../result/ResultView';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

export interface ICircularProps {
    label?: string;
    slug?: string;
    value: number;
    compareScores?: ICompareScores[];
    verbatim?: string;
    minScore?: number;
    maxScore?: number;
}

const Linear: React.FC<ICircularProps> = ({ value, slug, label, compareScores, verbatim, minScore, maxScore }) => {
    const { t } = useTranslation();
    const [showVerbatim, setShowVerbatim] = React.useState(false);

    return (
        <Box className={style.container}>
            <Box className={style.label}>
                <Typography>
                    {label}{' '}
                    {verbatim && (
                        <IconButton onClick={() => setShowVerbatim(!showVerbatim)} className={style.verbatimCTA}>
                            <AiOutlineQuestionCircle />
                        </IconButton>
                    )}
                </Typography>
                {verbatim && (
                    <Collapse in={showVerbatim}>
                        <Typography>
                            <Box className={style.verbatimScoreContainer}>
                                <span className={style.scoreMin}>{minScore}</span>
                                <span className={style.separator}>
                                    <BsChevronLeft fontSize="2rem" />
                                </span>
                                <span className={style.score}>{value}</span>
                                <span className={style.separator}>
                                    <BsChevronLeft fontSize="2rem" />
                                </span>
                                <span className={style.scoreMax}>{maxScore}</span>
                            </Box>
                            {verbatim}
                        </Typography>
                    </Collapse>
                )}
            </Box>
            <Box className={style.progressContainer}>
                <Box className={style.linearContainer}>
                    <LinearProgress
                        className={style.bar}
                        variant="determinate"
                        value={value}
                        sx={{
                            '& .MuiLinearProgress-bar1Determinate': {
                                backgroundColor: `hsl(${value}, 90%, 50%)`,
                            },
                        }}
                    />

                    {compareScores &&
                        compareScores.map((compareScore, key) => {
                            const scoreSection = compareScore.scorePerSection.find((score) => score.slug === slug);

                            return (
                                <span key={key} className={`${style.compareCursor} ${style[`color${key > 6 ? Math.round(key / 6) : key}`]}`} style={{ left: `calc(${scoreSection?.score}% - 20px)` }}>
                                    <BsFillTriangleFill /> <Typography fontSize={14} className={style[`color${key > 6 ? Math.round(key / 6) : key}`]}>{`${scoreSection?.score}%`}</Typography>
                                </span>
                            );
                        })}

                    {minScore && (
                        <span className={style.minScore} style={{ left: `calc(${minScore}% - 4px)` }}>
                            |
                        </span>
                    )}
                    {maxScore && (
                        <span className={style.maxScore} style={{ left: `calc(${maxScore}% - 4px)` }}>
                            |
                        </span>
                    )}
                </Box>
                <Typography className={style.text}>{t('percent', { ns: 'common', value: value })}</Typography>
            </Box>
        </Box>
    );
};

export default Linear;
