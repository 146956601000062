import React from 'react';
import { Link as RouteLink, useSearchParams } from 'react-router-dom';
import { Box, Drawer, IconButton, List, ListItemButton, Typography, useMediaQuery } from '@mui/material';

import style from './NavBar.module.scss';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_WELCOME } from '../../utils/welcome';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { checkSectionsAvailable, getAnswersArrayFilteredByVersion } from '../../utils/checkAnswers';
import { filterQuestionsByRole, getUserRoles } from '../../utils/nextPrevFlow';
import { useTheme } from '@emotion/react';

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import ConfettiExplosion from 'react-confetti-explosion';
import Robot from '../progress/robot/Robot';

export interface INavBarProps {}

const NavBar: React.FC<INavBarProps> = () => {
    const [openNav, setOpenNav] = React.useState(false);

    const { sectionSlug } = useCustomUseParams();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);

    const currentSectionId = globalEnvReducer.data?.sections.findIndex((section) => section.slug === sectionSlug);

    const userRoles = getUserRoles(globalEnvReducer);

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    const [isExploding, setIsExploding] = React.useState(false);
    const [searchParams] = useSearchParams();
    const sectionUrlParam = searchParams.get('section');

    React.useEffect(() => {
        if (sectionUrlParam === 'finished') {
            setIsExploding(true);
        }
    }, [sectionUrlParam]);

    if (userRoles === undefined) {
        return <></>;
    }

    const navMenu = (
        <List className={`${style.container} ${underThanMid ? '' : style.containerWithBorder}`}>
            {globalEnvReducer.data?.sections
                .filter((section) => filterQuestionsByRole(userRoles, section)?.length > 0)
                .map((section, key) => {
                    let res: JSX.Element;
                    const isAvailable = currentSectionId !== undefined && key > currentSectionId ? checkSectionsAvailable(key, globalEnvReducer, userRoles) : true;

                    let sectionIsFinished = false;
                    let totalAnsweredQuestion = 0;
                    if (globalEnvReducer.user?.sections[key] && globalEnvReducer.user?.sections[key].answersArray) {
                        const answersFiltredByVersion = getAnswersArrayFilteredByVersion(globalEnvReducer.user?.sections[key].answersArray, globalEnvReducer);
                        sectionIsFinished = globalEnvReducer.user?.sections[key] && answersFiltredByVersion?.length >= filterQuestionsByRole(userRoles, globalEnvReducer.data?.sections[key])?.length ? true : false;
                        totalAnsweredQuestion = answersFiltredByVersion?.length + 1;
                    }

                    if (isAvailable) {
                        res = (
                            <RouteLink key={section.name} to={section.slug === SECTION_WELCOME ? `/` : `/${section.slug}/${sectionIsFinished ? (globalEnvReducer.data?.sections[key].description ? 0 : 1) : totalAnsweredQuestion}`} style={{ all: 'unset' }} onClick={() => setOpenNav(false)}>
                                <ListItemButton className={style.link} selected={section.slug === sectionSlug}>
                                    <Typography variant={section.slug === sectionSlug ? 'body2' : 'body1'}>{section.name}</Typography>
                                    {isExploding && section.slug === sectionSlug && <ConfettiExplosion onComplete={() => setIsExploding(false)} />}
                                </ListItemButton>
                            </RouteLink>
                        );
                    } else {
                        res = (
                            <ListItemButton key={section.name} className={style.link} selected={section.slug === sectionSlug} disabled>
                                <Typography variant={section.slug === sectionSlug ? 'body2' : 'body1'}>{section.name}</Typography>
                            </ListItemButton>
                        );
                    }

                    return res;
                })}
            <Box className={style.progressBarContainer}>
                <Robot />
            </Box>
        </List>
    );

    return (
        <>
            {underThanMid ? (
                <Box className={style.mobilNav}>
                    <IconButton className={style.openNavCTA} onClick={() => setOpenNav(true)}>
                        <AiOutlineMenu />
                    </IconButton>
                    <Drawer anchor="left" open={openNav} onClose={() => setOpenNav(false)}>
                        <IconButton className={style.closeNavCTA} onClick={() => setOpenNav(false)}>
                            <AiOutlineClose />
                        </IconButton>
                        {navMenu}
                    </Drawer>
                </Box>
            ) : (
                navMenu
            )}
        </>
    );
};

export default NavBar;
