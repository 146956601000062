import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink } from 'react-router-dom';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_WELCOME } from '../../utils/welcome';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import style from './Home.module.scss';

export interface IHomeProps {}

const Home: React.FC<IHomeProps> = () => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { t } = useTranslation();

    return (
        <Box className={style.container}>
            <Box className={style.content}>
                <Typography variant="h1" className={style.title}>
                    {globalEnvReducer?.data?.meta?.mainTitle}
                </Typography>

                <Box className={style.logos}>
                    {globalEnvReducer?.data?.meta.logos?.map((logo, key) => (
                        <img key={key} src={logo} alt="logo" />
                    ))}
                </Box>

                {globalEnvReducer?.data?.meta.intro && (
                    <Typography className={style.description}>
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{globalEnvReducer.data.meta.intro}</ReactMarkdown>
                    </Typography>
                )}

                <Box className={style.startCTA}>
                    <RouteLink to={`/${SECTION_WELCOME}/2`} style={{ all: 'unset' }}>
                        <Button variant="contained">{t('welcome.startCTA', { ns: 'common' })}</Button>
                    </RouteLink>
                </Box>
            </Box>
        </Box>
    );
};

export default Home;
