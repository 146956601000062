import React, { PropsWithChildren } from 'react';
import { Box, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import style from './Matrix.module.scss';
import { IQuestion } from '../../../types/data';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import MobileSelect from './mobileSelect/MobileSelect';

export interface IMatrixProps extends PropsWithChildren {
    question: IQuestion;
    answer?: string[];
    callBack: (answer: string[]) => void;
}

const Matrix: React.FC<IMatrixProps> = ({ question, answer, callBack, children }) => {
    const { t } = useTranslation();
    const [answerValue, setAnswerValue] = React.useState<string[]>(Array(question.subquestions?.length).fill(''));

    const {
        setValue: setValueMatrix,
        setError: setErrorMatrix,
        clearErrors: clearErrorsMatrix,
        handleSubmit: handleSubmitMatrix,
        formState: { errors: errorsMatrix },
        reset,
    } = useForm<any>({
        defaultValues: { answer },
    });

    const onSubmitMatrixHandler = handleSubmitMatrix((formData: { answer: string[] }) => {
        let error = false;
        for (let i = 0; i < formData.answer.length; i++) {
            if (!formData.answer[i]) {
                setErrorMatrix(`subquestion-${i}`, { type: 'manual', message: `need anwser subquestion ${i}` });
                error = true;
            }
        }

        if (!error) {
            resetMatrix();
            callBack(formData.answer);
        }
    });

    const resetMatrix = () => {
        reset();
        setAnswerValue(Array(question.subquestions?.length).fill(''));
        clearErrorsMatrix();
    };

    React.useEffect(() => {
        resetMatrix();
        const newValue = Array(question.subquestions?.length).fill('');

        answer?.forEach((value, key) => {
            newValue[key] = value;
        });

        setAnswerValue(newValue);
        setValueMatrix('answer', newValue);
    }, [question]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const { value } = event.target;

        if (event.target.name.split('-')[1] === 'undefined') {
            return;
        }

        const subquestionId = parseInt(event.target.name.split('-')[1]);

        const newAnswer = [...answerValue];
        newAnswer[subquestionId] = value;
        setValueMatrix('answer', newAnswer);
        setAnswerValue(newAnswer);
        clearErrorsMatrix(`subquestion-${subquestionId}`);
    };

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    if (!answerValue) {
        return null;
    }

    return (
        <form onSubmit={onSubmitMatrixHandler} className={style.form}>
            <Box className={style.container}>
                <Table>
                    {!underThanMid && (
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {question.choices
                                    .filter((choice) => choice.text.length > 0)
                                    .map((choice, key) => (
                                        <TableCell key={`header-${key}`} align="center">
                                            {choice.text}
                                        </TableCell>
                                    ))}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {question.subquestions?.map((subquestion, subQuestionKey) => {
                            const subQuestionId = `subquestion-${subQuestionKey}`;

                            return (
                                <TableRow key={subQuestionId}>
                                    <TableCell className={style.questionRow}>
                                        {subquestion}
                                        <Typography color="error">{errorsMatrix[subQuestionId] && t('error.oneAnswerNeeded', { ns: 'common' })}</Typography>
                                        {underThanMid && <MobileSelect answerValue={answerValue} subQuestionId={subQuestionId} subQuestionKey={subQuestionKey} question={question} onChange={handleChange} />}
                                    </TableCell>
                                    {!underThanMid &&
                                        question.choices
                                            .filter((choice) => choice.text.length > 0)
                                            .map((choice, choiceKey) => (
                                                <TableCell key={`choice-${choiceKey}`} align="center">
                                                    <label className={style.label}>
                                                        <input type="radio" name={subQuestionId} value={choice.weight} checked={answerValue.length > subQuestionKey && answerValue[subQuestionKey] === `${choice.weight}`} onChange={handleChange} />
                                                    </label>
                                                </TableCell>
                                            ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                <Typography color="error">{errorsMatrix.answer && t('error.oneAnswerNeeded', { ns: 'common' })}</Typography>
            </Box>

            {children}
        </form>
    );
};

export default Matrix;
