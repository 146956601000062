import React from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import style from './LocalSwitcher.module.scss';

interface ILocalSwitcherProps {}

const LocalSwitcher: React.FC<ILocalSwitcherProps> = () => {
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = React.useState(i18n.language);

    const handleChange = (event: SelectChangeEvent) => {
        setCurrentLang(event.target.value);
        i18n.changeLanguage(event.target.value);
    };

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box className={`${style.container}`}>
            <FormControl fullWidth>
                <Select onChange={handleChange} value={currentLang}>
                    {i18n.languages.map((language) => (
                        <MenuItem key={language} value={language}>
                            <Typography fontFamily="VT323">{underThanMid ? t(`lang.${language}`, { ns: 'common' }).slice(0, 2) : t(`lang.${language}`, { ns: 'common' })}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default LocalSwitcher;
