import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setData, setUserId } from '../features/globalEnvSlice';
import { usePostUserMutation } from '../services/user';
import { loadLocalState, saveLocalState } from '../stores/localStorage';
import { useAppDispatch } from './storesHooks';
import { getBackendUrl } from '../utils/url';

export function useLoadUser() {
    const dispatch = useAppDispatch();
    const [postUser] = usePostUserMutation();

    const loadUser = async () => {
        const localStorage = loadLocalState();

        if (localStorage && localStorage.userId) {
            dispatch(setUserId(localStorage.userId));
        } else {
            const res = await postUser();
            if ('data' in res) {
                dispatch(setUserId(res.data));
                saveLocalState({ userId: res.data });
            }
        }
    };

    return { loadUser };
}

export function loadData() {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();

    // try to load a data to get the list of languages
    useEffect(() => {
        const load = async () => {
            for (const lang of i18n.languages) {
                let initOk = false;
                try {
                    await fetch(`${getBackendUrl()}/data/${lang}`)
                        .then((response) => response.json())
                        .then((json) => {
                            if (json.meta?.languages) {
                                initOk = true;
                                i18n.changeLanguage(json.meta.languages[0]);
                            }
                        });
                } catch (e) {
                    console.error(e);
                }

                if (initOk) {
                    break;
                }
            }
        };

        load();
    }, []);

    // // load data (company, questions, ...)
    useEffect(() => {
        try {
            fetch(`${getBackendUrl()}/data/${i18n.language}`)
                .then((response) => response.json())
                .then((json) => {
                    if (json.meta) {
                        dispatch(setData(json));
                    }
                });
        } catch (e) {
            console.error(e);
        }
    }, [i18n.language]);
}
