import { Button, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useAppDispatch, useAppSelector } from '../../hooks/storesHooks';
import { EQuestionType } from '../../types/data';
import PrevCta from '../cta/prev/PrevCta';

import style from './Question.module.scss';
import { useTranslation } from 'react-i18next';
import { getCurrentQuestion, getUserRoles, nextQuestion } from '../../utils/nextPrevFlow';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserMutation } from '../../services/user';
import { IAnswer, IAnswerSection, IUser } from '../../types/api';
import { errorReload } from '../../utils/reload';
import { setUser } from '../../features/globalEnvSlice';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { useTheme } from '@emotion/react';
import { AiOutlineClose, AiOutlineQuestionCircle } from 'react-icons/ai';
import { SECTION_WELCOME } from '../../utils/welcome';
import Matrix from './matrix/Matrix';
import Single from './single/Single';
import Open from './open/Open';
import Multiple from './multiple/Multiple';
import { getAnswerFilteredByVersion } from '../../utils/checkAnswers';

export interface IQuestionProps {}

const Question: React.FC<IQuestionProps> = () => {
    const [openExample, setOpenExample] = React.useState(false);
    const [disableCta, setDisableCta] = React.useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { sectionSlug, questionId } = useCustomUseParams();
    const questionIndex = questionId ? questionId - 1 : undefined;
    const userRoles = getUserRoles(globalEnvReducer);

    const [updateUser] = useUpdateUserMutation();

    const question = getCurrentQuestion(sectionSlug, questionId, globalEnvReducer);
    const currentAnswers = globalEnvReducer.user?.sections?.find((section) => section.slug === sectionSlug)?.answersArray;
    const currentAnswer = questionIndex !== undefined && currentAnswers ? currentAnswers[questionIndex] : undefined;
    const [startDate, setStartDate] = React.useState<Date | null>(null);

    useEffect(() => {
        setStartDate(new Date());
        setOpenExample(false);
        setDisableCta(false);
    }, [sectionSlug, questionId]);

    const sendAnswerCallBack = (res: boolean) => {
        if (res) {
            nextQuestion(sectionSlug, questionId, globalEnvReducer, navigate);
        } else {
            errorReload(t('error.async', { ns: 'common' }));
        }
    };

    const sendAnswer = (answer: string[], callback: (res: boolean) => void) => {
        if (globalEnvReducer.user && sectionSlug) {
            // lock cta
            setDisableCta(true);

            const userClone: IUser = { ...globalEnvReducer.user };

            if (!userClone.sections) {
                userClone.sections = [] as IAnswerSection[];
            }

            let answerSection: IAnswerSection | undefined = userClone.sections.find((answerSection) => answerSection.slug === sectionSlug);
            let answerSectionAlreadyExist = false;
            const emptyAnswers: IAnswer = {
                answers: [''],
                timer: 0,
                version: globalEnvReducer.data?.meta.version || '0',
            };

            if (!answerSection) {
                answerSection = { slug: sectionSlug, answersArray: [] } as IAnswerSection;
                // if question is not the first one, we need to fill the answers array with empty string to avoid null values
                if (questionIndex && questionIndex > 0) {
                    answerSection.answersArray = Array(questionIndex).fill([emptyAnswers]);
                }
            } else {
                answerSectionAlreadyExist = true;
            }

            if (answerSection && globalEnvReducer.userId && questionIndex !== undefined) {
                const cloneAnswers: Array<IAnswer[]> = [];
                answerSection.answersArray.forEach((answerVersion, key) => {
                    cloneAnswers[key] = [];
                    answerVersion.forEach((answer) => {
                        cloneAnswers[key].push({ ...answer });
                    });
                });

                const newAnswer: IAnswer = {
                    answers: answer,
                    timer: startDate ? new Date().getTime() - startDate.getTime() : 0,
                    version: globalEnvReducer.data?.meta.version || '0',
                };

                // check if answerArray exist for this questionId
                if (cloneAnswers.length <= questionIndex) {
                    cloneAnswers[questionIndex] = [];
                }

                // check if an answer with the same version already exist
                let answerAlreadyExist = false;
                for (let i = 0; i < cloneAnswers[questionIndex].length; i++) {
                    if (cloneAnswers[questionIndex][i].version === newAnswer.version) {
                        cloneAnswers[questionIndex][i] = { ...newAnswer };
                        answerAlreadyExist = true;
                        break;
                    }
                }

                // if not, we push the new answer
                if (!answerAlreadyExist) {
                    cloneAnswers[questionIndex].push(newAnswer);
                }

                if (answerSectionAlreadyExist) {
                    const index = userClone.sections.findIndex((answerSection) => answerSection.slug === sectionSlug);
                    const cloneSections = [...userClone.sections];
                    cloneSections[index] = { ...answerSection, answersArray: cloneAnswers };

                    // check if the section exist in the current version
                    // if not add empty answers to the answersArray (useful for welcome section)
                    for (let i = 0; i < cloneAnswers.length && i < questionIndex; i++) {
                        const answersFiltered = getAnswerFilteredByVersion(cloneAnswers[i], globalEnvReducer);

                        if (!answersFiltered) {
                            cloneAnswers[i].push(emptyAnswers);
                        }
                    }

                    userClone.sections = cloneSections;
                } else {
                    userClone.sections = [...userClone.sections, { ...answerSection, answersArray: cloneAnswers }];
                }

                updateUser({ userId: globalEnvReducer.userId, updateParam: userClone }).then((res) => {
                    if ('data' in res) {
                        // @ts-ignore
                        if (res.data.error) {
                            // @ts-ignore
                            if (res.data.status === 404) {
                                errorReload(t('error.404', { ns: 'common' }));
                            } else {
                                errorReload(t('error.async', { ns: 'common' }));
                            }
                            callback(false);
                            return;
                        } else {
                            dispatch(setUser(userClone));
                            callback(true);
                            return;
                        }
                    } else {
                        errorReload(t('error.async', { ns: 'common' }));
                        callback(false);
                        return;
                    }
                });
            }
        }
    };

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    if (!globalEnvReducer.data || questionId === undefined || !question) {
        return null;
    }

    let questionForm: JSX.Element | null = null;

    const ctaContainer = (
        <Box className={style.ctaContainer}>
            <PrevCta disabled={disableCta} />
            <Button type="submit" variant="contained" disabled={disableCta}>
                {t('next', { ns: 'common' })}
            </Button>

            {sectionSlug !== SECTION_WELCOME && (
                <Button onClick={() => sendAnswer(getAnswerFilteredByVersion(currentAnswer, globalEnvReducer)?.answers || [], sendAnswerCallBack)} style={{ color: 'grey' }} disabled={disableCta}>
                    {t('skip', { ns: 'common' })}
                </Button>
            )}
        </Box>
    );

    switch (question.type) {
        case EQuestionType.SINGLE:
            questionForm = (
                <Single question={question} answer={getAnswerFilteredByVersion(currentAnswer, globalEnvReducer)?.answers} callBack={(answer: string[]) => sendAnswer(answer, sendAnswerCallBack)} sectionSlug={sectionSlug} questionId={questionId}>
                    {ctaContainer}
                </Single>
            );
            break;

        case EQuestionType.OPEN:
            questionForm = (
                <Open question={question} answer={getAnswerFilteredByVersion(currentAnswer, globalEnvReducer)?.answers} callBack={(answer: string[]) => sendAnswer(answer, sendAnswerCallBack)}>
                    {ctaContainer}
                </Open>
            );
            break;

        case EQuestionType.MULTIPLE:
            questionForm = (
                <Multiple question={question} answer={getAnswerFilteredByVersion(currentAnswer, globalEnvReducer)?.answers} callBack={(answer: string[]) => sendAnswer(answer, sendAnswerCallBack)}>
                    {ctaContainer}
                </Multiple>
            );
            break;

        case EQuestionType.MATRIX:
            questionForm = (
                <Matrix question={question} answer={getAnswerFilteredByVersion(currentAnswer, globalEnvReducer)?.answers} callBack={(answer: string[]) => sendAnswer(answer, sendAnswerCallBack)}>
                    {ctaContainer}
                </Matrix>
            );
            break;
    }

    const example = question?.examples?.find((example) => example.scope.find((scope) => userRoles.includes(scope)) !== undefined && example.text.length > 1);

    return (
        <Box className={style.container}>
            <Box className={`${style.left} ${!example || underThanMid || question.type === EQuestionType.MATRIX ? (underThanMid ? style.mobile : style.full) : ''}`}>
                <Typography variant="h4">{question.theme}</Typography>

                <Typography className={`${style.quesiton} ${underThanMid || question.type === EQuestionType.MATRIX ? style.mobile : ''}`}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{question.text}</ReactMarkdown>
                    {example && (underThanMid || question.type === EQuestionType.MATRIX) && (
                        <IconButton className={style.exampleCTA} onClick={() => setOpenExample(true)} size="large">
                            <AiOutlineQuestionCircle />
                        </IconButton>
                    )}
                </Typography>

                {questionForm}
            </Box>
            {example &&
                (underThanMid || question.type === EQuestionType.MATRIX ? (
                    <Drawer anchor="right" open={openExample} onClose={() => setOpenExample(false)}>
                        <Box className={style.righMobile}>
                            <Box className={style.CTAContainer}>
                                <IconButton className={style.closeExampleCTA} onClick={() => setOpenExample(false)}>
                                    <AiOutlineClose />
                                </IconButton>
                            </Box>

                            <Typography>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>{example.text}</ReactMarkdown>
                            </Typography>
                        </Box>
                    </Drawer>
                ) : (
                    <Box className={style.right}>
                        <Typography>
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{example.text}</ReactMarkdown>
                        </Typography>
                    </Box>
                ))}
        </Box>
    );
};

export default Question;
