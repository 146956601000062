import { Box, useMediaQuery } from '@mui/material';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/storesHooks';
import NavBar from '../../components/navBar/NavBar';
import Header from '../../components/header/Header';
import { useNavigate } from 'react-router-dom';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { checkPreviousQuestions } from '../../utils/checkAnswers';
import { useTheme } from '@emotion/react';
import { getUserRoles } from '../../utils/nextPrevFlow';

import style from './DefaultLayout.module.scss';
import { SECTION_COMPANY_RESULTS } from '../../utils/welcome';

export interface IDefaultLayoutProps extends PropsWithChildren {
    showNavegation?: boolean;
}

const DefaultLayout: React.FC<IDefaultLayoutProps> = ({ showNavegation, children }) => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const navigate = useNavigate();
    const { sectionSlug, questionId } = useCustomUseParams();
    const [checkedPreviousAnswers, setCheckedPreviousAnswers] = useState(false);
    const userRoles = getUserRoles(globalEnvReducer);
    const matchCompanyPage = window.location.pathname.indexOf(SECTION_COMPANY_RESULTS) > -1;

    // check if all previous question are answered
    useEffect(() => {
        if (matchCompanyPage) {
            setCheckedPreviousAnswers(true);
        } else {
            if (globalEnvReducer.user && userRoles) {
                const url = checkPreviousQuestions(sectionSlug, questionId, globalEnvReducer, userRoles);

                if (url === undefined) {
                    return;
                }

                setCheckedPreviousAnswers(true);

                if (url !== '') {
                    if (url !== decodeURI(window.location.pathname)) {
                        navigate(url);
                        return;
                    }
                }
            }
        }
    }, [globalEnvReducer.user, userRoles]);

    const theme = useTheme();
    // @ts-ignore
    const underThanMid = useMediaQuery(theme.breakpoints.down('lg'));

    if (checkedPreviousAnswers === false) {
        return null;
    }

    return (
        <Box className={style.container}>
            <Header />

            <Box className={`${style.body} ${underThanMid ? style.mobile : ''}`}>
                {showNavegation && <NavBar />}

                <Box className={style.pageContent}>{children}</Box>
            </Box>
        </Box>
    );
};

export default DefaultLayout;
