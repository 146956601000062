export interface ILocalStorage {
    userId: string;
}

export const loadLocalState = (): ILocalStorage | undefined => {
    try {
        const serializedState = localStorage.getItem('maturityData');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveLocalState = (stateToSave: ILocalStorage) => {
    try {
        const serializedState = JSON.stringify(stateToSave);
        localStorage.setItem('maturityData', serializedState);
    } catch {
        // ignore write errors
    }
};
