import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: 'Hanken Grotesk, Arial, sans-serif',
        fontSize: 24,
        htmlFontSize: 24,
    },
    palette: {
        primary: {
            main: '#001EC6',
        },
        secondary: {
            main: '#000000',
        },
        error: {
            main: '#FF0000',
        },
    },
});

defaultTheme.typography.h1 = {
    fontSize: 128,
    fontFamily: 'Hanken Grotesk',
    [defaultTheme.breakpoints.down('lg')]: {
        fontSize: 64,
    },
};

defaultTheme.typography.h2 = {
    fontSize: 64,
    fontFamily: 'Hanken Grotesk',
    padding: '2rem',

    [defaultTheme.breakpoints.down('lg')]: {
        fontSize: 32,
    },
};

defaultTheme.typography.body1 = {
    fontFamily: 'Dinish',
    fontSize: 24,
    color: '#000000',
};

defaultTheme.typography.body2 = {
    fontFamily: 'Dinish',
    fontSize: 24,
    color: '#ffffff',
};

defaultTheme.typography.button = {
    fontFamily: 'VT323',
    fontSize: 24,
    color: '#ffffff',
};

export default defaultTheme;
