import React, { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, Typography } from '@mui/material';
import { SECTION_WELCOME, currentWelcomeMaxPage } from '../../../utils/welcome';

import style from './Single.module.scss';
import { IQuestion } from '../../../types/data';
import { useAppSelector } from '../../../hooks/storesHooks';
import { useTranslation } from 'react-i18next';

export interface SingleProps extends PropsWithChildren {
    question: IQuestion;
    answer?: string[];
    callBack: (answer: string[]) => void;
    sectionSlug?: string;
    questionId?: number;
}

export interface IQuestionSingleForm {
    answer: string;
}

const Single: React.FC<SingleProps> = ({ question, answer, callBack, sectionSlug, questionId, children }) => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { t } = useTranslation();

    const formSingleSchema = yup.object().shape({
        answer: yup.string().required(),
    });

    const {
        register: registerSingle,
        handleSubmit: handleSubmitSingle,
        formState: { errors: errorsSingle },
        reset: resetSingle,
    } = useForm<IQuestionSingleForm>({
        defaultValues: { answer: answer ? answer[0] : '' },
        resolver: yupResolver(formSingleSchema),
    });

    const onSubmitSingleHandler = handleSubmitSingle((formData) => {
        callBack([formData.answer]);
    });

    React.useEffect(() => {
        resetSingle({ answer: answer ? answer[0] : '' });
    }, [question]);

    return (
        <form onSubmit={onSubmitSingleHandler} className={style.form}>
            <FormGroup>
                {question.choices
                    .filter((choice) => choice.text.length > 0)
                    .map((choice, key) => (
                        <label key={key} className={style.label}>
                            <input type="radio" {...registerSingle('answer')} value={sectionSlug === SECTION_WELCOME && questionId === currentWelcomeMaxPage(globalEnvReducer.data?.meta.company ? true : false) ? choice.weight : key} />
                            <Typography className={style.labelText}>{choice.text}</Typography>
                        </label>
                    ))}
                <Typography color="error">{errorsSingle.answer && t('error.oneAnswerNeeded', { ns: 'common' })}</Typography>
            </FormGroup>

            {children}
        </form>
    );
};

export default Single;
