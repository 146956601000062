import { Box, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/storesHooks';
import { useInitUserQuery } from '../../services/user';
import { currentWelcomeMaxPage } from '../../utils/welcome';

import style from './Init.module.scss';
import { EEntryPoint } from '../../types/api';
import { api } from '../../services/api';

export interface InitProps {
    crypted: string;
}

const Init: React.FC<InitProps> = ({ crypted }) => {
    const navigate = useNavigate();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const dispatch = useAppDispatch();

    const { data: userLoaded } = useInitUserQuery({ userId: globalEnvReducer.userId ? globalEnvReducer.userId : '', crypted: crypted ? crypted : '', welcomeMaxPage: currentWelcomeMaxPage(globalEnvReducer.data?.meta?.company ? true : false) }, { skip: !crypted || !globalEnvReducer.userId || !globalEnvReducer.data });

    if (!crypted) {
        navigate('/');
        return null;
    }

    useEffect(() => {
        if (userLoaded) {
            dispatch(api.util.invalidateTags([{ type: EEntryPoint.USER, id: 'LIST' }]));
            navigate('/');
        }
    }, [userLoaded]);

    return (
        <Box className={style.container}>
            <CircularProgress size="10rem" />
        </Box>
    );
};

export default Init;
