import React from 'react';
import Question from '../../components/question/Question';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_WELCOME } from '../../utils/welcome';
import Company from '../company/Company';
import { Box, Typography } from '@mui/material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import PrevCta from '../../components/cta/prev/PrevCta';
import NextCta from '../../components/cta/next/NextCta';

import style from './Questions.module.scss';

export interface IQuestionsProps {}

const Questions: React.FC<IQuestionsProps> = () => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { sectionSlug, questionId } = useCustomUseParams();

    window.scrollTo(0, 0); // scroll to top

    // special case for company
    if (sectionSlug === SECTION_WELCOME && globalEnvReducer.data?.meta.company && questionId === 2) {
        return <Company />;
    }

    // special case for description
    const currentSection = globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug);
    if (questionId === 0 && currentSection?.description) {
        return (
            <Box className={style.descriptionContainer}>
                <Typography variant="h4">{currentSection.name}</Typography>

                <Typography>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{currentSection?.description}</ReactMarkdown>
                </Typography>
                <Box className={style.ctaContainer}>
                    <PrevCta />
                    <NextCta />
                </Box>
            </Box>
        );
    }

    // show question
    return <Question />;
};

export default Questions;
