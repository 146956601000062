import React from 'react';
import { Box } from '@mui/material';
import './core/i18n';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import DefaultLayout from './core/layout/default/DefaultLayout';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { SECTION_COMPANY_RESULTS, SECTION_INFORMATIONS, SECTION_RESULT, SECTION_THANK_YOU } from './core/utils/welcome';

import Questions from './core/pages/questions/Questions';
import Result from './core/pages/result/Result';

import style from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import defaultTheme from './core/theme/defaultTheme';

import { store } from './core/stores/store';
import NotFound from './core/pages/notFound/NotFound';
import Informations from './core/pages/informations/Informations';
import InitApp from './core/components/initApp/InitApp';
import ThankYou from './core/pages/thankYou/ThankYou';
import CustomeRouter from './core/pages/customeRouter/CustomeRouter';
import CompanyResult from './core/pages/companyResults/CompanyResults';

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <InitApp>
                <React.StrictMode>
                    <Router>
                        <ThemeProvider theme={defaultTheme}>
                            <Box className={style.app}>
                                <Box className={style.content}>
                                    <Routes>
                                        <Route path="/" element={<CustomeRouter />} />
                                        <Route
                                            path={`/:sectionSlug/:questionId`}
                                            element={
                                                <DefaultLayout showNavegation={true}>
                                                    <Questions />
                                                </DefaultLayout>
                                            }
                                        />
                                        <Route
                                            path={`/${SECTION_INFORMATIONS}`}
                                            element={
                                                <DefaultLayout>
                                                    <Informations />
                                                </DefaultLayout>
                                            }
                                        />
                                        <Route
                                            path={`/${SECTION_RESULT}`}
                                            element={
                                                <DefaultLayout>
                                                    <Result />
                                                </DefaultLayout>
                                            }
                                        />
                                        <Route
                                            path={`/${SECTION_THANK_YOU}`}
                                            element={
                                                <DefaultLayout>
                                                    <ThankYou />
                                                </DefaultLayout>
                                            }
                                        />
                                        <Route
                                            path={`/${SECTION_COMPANY_RESULTS}`}
                                            element={
                                                <DefaultLayout>
                                                    <CompanyResult />
                                                </DefaultLayout>
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <DefaultLayout showNavegation={false}>
                                                    <NotFound />
                                                </DefaultLayout>
                                            }
                                        />
                                    </Routes>
                                </Box>
                            </Box>
                            <ToastContainer />
                        </ThemeProvider>
                    </Router>
                </React.StrictMode>
            </InitApp>
        </Provider>
    );
};

export default App;
