import React from 'react';

import style from './MobileSelect.module.scss';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IQuestion } from '../../../../types/data';

export interface IMobileSelectProps {
    answerValue: string[];
    subQuestionId: string;
    subQuestionKey: number;
    question: IQuestion;
    onChange: (event: SelectChangeEvent) => void;
}

const MobileSelect: React.FC<IMobileSelectProps> = ({ answerValue, subQuestionId, subQuestionKey, question, onChange }) => {
    const getCurrentAnswer = () => {
        return answerValue && answerValue.length > subQuestionKey && answerValue[subQuestionKey].length > 0 ? answerValue[subQuestionKey] : 'undefined';
    };

    const [answer, setAnswer] = React.useState<string | undefined>(getCurrentAnswer());

    const handleChange = (event: SelectChangeEvent) => {
        setAnswer(event.target.value as string);
        onChange(event);
    };

    React.useEffect(() => {
        setAnswer(getCurrentAnswer());
    }, [answerValue]);

    if (!answer) {
        return null;
    }

    return (
        <>
            <Select className={style.container} onChange={handleChange} name={subQuestionId} value={answer}>
                <MenuItem key={`choice-undefined`} value="undefined" style={{ display: 'none' }}></MenuItem>
                {question.choices
                    .filter((choice) => choice.text.length > 0)
                    .map((choice, choiceKey) => (
                        <MenuItem key={`choice-${choiceKey}`} value={choice.weight.toString()}>
                            {choice.text}
                        </MenuItem>
                    ))}
            </Select>
        </>
    );
};

export default MobileSelect;
