import React, { PropsWithChildren, useEffect, useState } from 'react';
import { setUser } from '../../features/globalEnvSlice';
import { loadData, useLoadUser } from '../../hooks/loadHooks';
import { useAppDispatch, useAppSelector } from '../../hooks/storesHooks';
import { useGetUserQuery } from '../../services/user';
import i18n, { defaultNS, resources } from '../../i18n';

export interface InitAppProps extends PropsWithChildren {}

const InitApp: React.FC<InitAppProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const [dataAndUserLoaded, setDataAndUserLoaded] = useState(false);
    const [i18nReady, setI18nReady] = useState(false);

    // load local storage
    const { loadUser } = useLoadUser();

    useEffect(() => {
        loadUser();
    }, []);

    // load data (company, questions, ...)
    loadData();

    useEffect(() => {
        if (!dataAndUserLoaded && globalEnvReducer.data && globalEnvReducer.user) {
            setDataAndUserLoaded(true);
        }
    }, [globalEnvReducer.data, globalEnvReducer.user]);

    useEffect(() => {
        if (globalEnvReducer.data?.meta?.languages && !i18nReady) {
            i18n.init({
                lng: globalEnvReducer.data.meta.languages[0],
                fallbackLng: globalEnvReducer.data.meta.languages,
                ns: ['common'],
                defaultNS,
                resources,
            });

            setI18nReady(true);
        }
    }, [globalEnvReducer.data]);

    const { data: userLoaded } = useGetUserQuery(globalEnvReducer.userId ? globalEnvReducer.userId : '', { skip: !globalEnvReducer.userId });

    useEffect(() => {
        if (userLoaded) {
            dispatch(setUser(userLoaded));
        }
    }, [userLoaded]);

    if (dataAndUserLoaded === false) {
        return null;
    }

    return <>{children}</>;
};

export default InitApp;
