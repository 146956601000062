import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import common_en from './locales/en/common.json';
import common_fr from './locales/fr/common.json';
import common_es from './locales/es/common.json';
import common_it from './locales/it/common.json';
import common_pt from './locales/pt/common.json';
import common_de from './locales/de/common.json';

export const defaultNS = 'common';

// the translations
export const resources = {
    en: {
        common: common_en,
    },
    fr: {
        common: common_fr,
    },
    es: {
        common: common_es,
    },
    it: {
        common: common_it,
    },
    pt: {
        common: common_pt,
    },
    de: {
        common: common_de,
    },
} as const;

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // lng: 'en', // set in InitApp.tsx
        fallbackLng: ['en', 'fr', 'es', 'it', 'pt', 'de'],
        ns: ['common'],
        defaultNS,
        resources,
    });

export default i18n;
