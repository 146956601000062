import { configureStore, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import i18next from 'i18next';
import { userApi } from '../services/user';
import { errorReload } from '../utils/reload';
import globalEnvStatusReducer from '../features/globalEnvSlice';

/**
 * In case of rtk query error display a toast and reload the page
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        errorReload(i18next.t('error.async', { ns: 'common' }));
    }

    return next(action);
};

export const store = configureStore({
    reducer: {
        globalEnvStatusReducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);
