import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './ThankYou.module.scss';

export interface IThankYouProps {}

const ThankYou: React.FC<IThankYouProps> = () => {
    const { t } = useTranslation();

    return (
        <Box className={style.container}>
            <Typography variant="h2">{t('page.thankYou.title', { ns: 'common' })}</Typography>
            <br />
            <br />
            <br />
            <Typography>{t('page.thankYou.text', { ns: 'common' })}</Typography>

            <img src="../images/robot/robot-idle.gif" alt="robot" />
        </Box>
    );
};

export default ThankYou;
