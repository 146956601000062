import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCustomUseParams } from '../../../hooks/customUseParamsHooks';
import { useAppSelector } from '../../../hooks/storesHooks';
import { nextQuestion } from '../../../utils/nextPrevFlow';

export interface INextCtaProps {
    label?: string;
    disabled?: boolean;
}

const NextCta: React.FC<INextCtaProps> = ({ label, disabled }) => {
    const { sectionSlug, questionId } = useCustomUseParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);

    const handleNext = () => {
        nextQuestion(sectionSlug, questionId, globalEnvReducer, navigate);
    };

    return (
        <Button disabled={disabled} type="submit" onClick={handleNext} variant="contained">
            {label ? label : t('next', { ns: 'common' })}
        </Button>
    );
};

export default NextCta;
