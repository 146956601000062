import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../types/api';
import { IData } from '../types/data';

export interface IGlobalEnvStatus {
    userId?: string;
    user?: IUser;
    data?: IData;
}

const initialState: IGlobalEnvStatus = {};

export const globalEnvStatusSlice = createSlice({
    name: 'globalEnvStatus',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        setUser: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload;
        },
        setData: (state, action: PayloadAction<IData>) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserId, setUser, setData } = globalEnvStatusSlice.actions;

export default globalEnvStatusSlice.reducer;
