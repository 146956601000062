import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { EEntryPoint } from '../types/api';
import { getBackendUrl } from '../utils/url';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: getBackendUrl(),
    prepareHeaders: (headers) => {
        return headers;
    },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const api = createApi({
    reducerPath: 'apiDataMaturityAssessment',
    baseQuery: baseQueryWithRetry,
    tagTypes: Object.values(EEntryPoint),
    endpoints: () => ({}),
});

export const enhancedApi = api.enhanceEndpoints({
    endpoints: {},
});
