export const MAX_WELCOME_SECTION_PAGE = 3;
export const SECTION_WELCOME = 'welcome';
export const SECTION_INFORMATIONS = 'informations';
export const SECTION_RESULT = 'result';
export const SECTION_THANK_YOU = 'thank-you';
export const SECTION_INIT = 'init';
export const SECTION_COMPANY_RESULTS = 'company-results';

export function currentWelcomeMaxPage(hasCompany: boolean) {
    return hasCompany ? MAX_WELCOME_SECTION_PAGE : MAX_WELCOME_SECTION_PAGE - 1;
}
