export enum EEntryPoint {
    USER = 'user',
}

export interface IUser {
    informations: IInformations;
    sections: IAnswerSection[];
}

export interface IInformations {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
}

export interface IAnswer {
    answers: string[];
    timer: number;
    version: string;
}
export interface IAnswerSection {
    slug: string;
    answersArray: Array<IAnswer[]>;
}
