import { api } from './api';
import { EEntryPoint, IUser } from '../types/api';

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<IUser, string>({
            query: (userId) => `${EEntryPoint.USER}/${userId}`,
            providesTags: (result, error, arg) => [
                { type: EEntryPoint.USER, id: arg },
                { type: EEntryPoint.USER, id: 'LIST' },
            ],
        }),
        initUser: builder.query<boolean, { userId: string; crypted: string; welcomeMaxPage: number }>({
            query: (params) => `${EEntryPoint.USER}/init/${params.userId}/${params.crypted}?welcomeMaxPage=${params.welcomeMaxPage}`,
        }),
        postUser: builder.mutation<string, void>({
            query: () => ({
                url: `${EEntryPoint.USER}`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: EEntryPoint.USER, id: 'LIST' }],
        }),
        updateUser: builder.mutation<void, { userId: string; updateParam: IUser }>({
            query: (patch) => ({
                url: `${EEntryPoint.USER}/${patch.userId}`,
                method: 'PUT',
                body: patch.updateParam,
            }),
            // optimistic system
            async onQueryStarted(patch, { dispatch, queryFulfilled }) {
                const patchUser = dispatch(
                    // @ts-ignore
                    api.util.updateQueryData('getUser', patch.userId, (draft: IUser) => {
                        Object.assign(draft, patch.updateParam);
                    })
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchUser.undo();
                }
            },
            // invalidatesTags: [{ type: EEntryPoint.USER, id: 'LIST' }],
        }),
    }),
});

export const { useGetUserQuery, useInitUserQuery, usePostUserMutation, useUpdateUserMutation } = userApi;
