import { IGlobalEnvStatus } from '../features/globalEnvSlice';
import { IAnswer } from '../types/api';
import { filterQuestionsByRole } from './nextPrevFlow';
import { currentWelcomeMaxPage, SECTION_INFORMATIONS, SECTION_RESULT, SECTION_THANK_YOU, SECTION_WELCOME } from './welcome';

export function checkPreviousQuestions(sectionSlug: string | undefined, questionId: number | undefined, globalEnvReducer: IGlobalEnvStatus, userRoles: number[]) {
    const currentSectionSlug = sectionSlug ? sectionSlug : window.location.pathname.replace('/', '');
    const currentQuestionId = questionId ? questionId : 0;

    // welcome page
    if (currentSectionSlug === '' || currentSectionSlug === SECTION_WELCOME) {
        return '';
    }

    if (globalEnvReducer.data && globalEnvReducer.user) {
        for (let sectionIndex = 0; sectionIndex < globalEnvReducer.data?.sections.length; sectionIndex++) {
            const section = globalEnvReducer.data.sections[sectionIndex];
            const totalQuestionInSection = filterQuestionsByRole(userRoles, section)?.length;

            if (totalQuestionInSection === 0) {
                continue;
            }

            const userSection = globalEnvReducer.user?.sections?.find((userSection) => userSection.slug === section.slug);

            if (userSection) {
                const totalDataQuestions = section.slug === SECTION_WELCOME ? currentWelcomeMaxPage(globalEnvReducer.data?.meta.company ? true : false) : totalQuestionInSection;
                for (let answerIndex = 0; answerIndex < totalDataQuestions; answerIndex++) {
                    if (section.slug === currentSectionSlug && answerIndex >= currentQuestionId) {
                        // current section answered
                        return '';
                    }

                    const answersArrayFiltredByVersion = getAnswersArrayFilteredByVersion(userSection.answersArray, globalEnvReducer);
                    if (answersArrayFiltredByVersion[answerIndex] === null || answersArrayFiltredByVersion[answerIndex] === undefined) {
                        // navigate to first question of section
                        return `/${section.slug}/${answerIndex + 1}`;
                    }
                }
            } else {
                const currentSection = globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug);

                // section not answered
                // navigate to first question of section
                return `/${section.slug}/${currentSection?.description ? (currentQuestionId < 2 ? currentQuestionId : 0) : 1}`;
            }

            if (section.slug === currentSectionSlug) {
                // all previous questions are answered
                return '';
            }
        }
    }

    if ([SECTION_RESULT, SECTION_INFORMATIONS, SECTION_THANK_YOU].indexOf(currentSectionSlug) > -1) {
        return '';
    }

    return undefined;
}

export function checkSectionsAvailable(sectionId: number, globalEnvReducer: IGlobalEnvStatus, userRoles: number[]) {
    if (sectionId && sectionId > 0) {
        const prevSection = globalEnvReducer.data?.sections[sectionId - 1];
        const nbPrevQuestions = filterQuestionsByRole(userRoles, prevSection)?.length;
        const nbPevAnswers = getAnswersArrayFilteredByVersion(globalEnvReducer.user?.sections?.find((userSection) => userSection.slug === prevSection?.slug)?.answersArray, globalEnvReducer)?.length;

        if (nbPevAnswers && nbPrevQuestions && nbPevAnswers >= nbPrevQuestions) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}

export function getAnswersArrayFilteredByVersion(answersArray: IAnswer[][] | undefined, globalEnvReducer?: IGlobalEnvStatus) {
    if (answersArray && globalEnvReducer) {
        return answersArray
            .map((answersVersion) => {
                const answerInGoodVersion = getAnswerFilteredByVersion(answersVersion, globalEnvReducer);

                if (answerInGoodVersion) {
                    return [answerInGoodVersion];
                } else {
                    return [];
                }
            })
            .filter((answersVersion) => answersVersion.length > 0);
    }

    return [];
}

export function getAnswerFilteredByVersion(answersVersion: IAnswer[] | undefined, globalEnvReducer?: IGlobalEnvStatus) {
    if (answersVersion && globalEnvReducer && globalEnvReducer.data?.meta.version) {
        const answerInGoodVersion = answersVersion.find((answer) => answer.version === globalEnvReducer.data?.meta.version);

        if (answerInGoodVersion) {
            return answerInGoodVersion;
        } else {
            // special case for the job question after init a user with a magic link
            return answersVersion.find((answer) => answer.version === '0');
        }
    }
}
