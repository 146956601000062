import { NavigateFunction } from 'react-router-dom';
import { IGlobalEnvStatus } from '../features/globalEnvSlice';
import { ISection } from '../types/data';
import { currentWelcomeMaxPage, SECTION_INFORMATIONS, SECTION_THANK_YOU, SECTION_WELCOME } from './welcome';
import { getAnswersArrayFilteredByVersion } from './checkAnswers';

export function nextQuestion(sectionSlug: string | undefined, questionId: number | undefined, globalEnvReducer: IGlobalEnvStatus, navigate: NavigateFunction) {
    const currentSection = globalEnvReducer.data?.sections.find((section: ISection) => section.slug === sectionSlug);
    const nextQuestionId = questionId ? questionId + 1 : 1;
    const indexQuestion = currentSection?.description ? nextQuestionId : nextQuestionId - 1;
    const currentSectionIndex = globalEnvReducer.data?.sections.findIndex((section: ISection) => section.slug === sectionSlug);
    const userRoles = getUserRoles(globalEnvReducer);
    const totalQuestions = sectionSlug === SECTION_WELCOME ? (globalEnvReducer.data?.meta.company ? 3 : 2) : filterQuestionsByRole(userRoles ? userRoles : [], currentSection)?.length;

    if (globalEnvReducer.data && currentSection && currentSectionIndex !== undefined && ((sectionSlug === SECTION_WELCOME && indexQuestion >= currentWelcomeMaxPage(globalEnvReducer.data?.meta.company ? true : false)) || (sectionSlug !== SECTION_WELCOME && indexQuestion > totalQuestions))) {
        // find next section
        let nextSectionIndex = currentSectionIndex + 1;
        for (; nextSectionIndex < globalEnvReducer.data.sections.length; nextSectionIndex++) {
            const nbNextSectionQuestions = filterQuestionsByRole(userRoles ? userRoles : [], globalEnvReducer.data?.sections[nextSectionIndex])?.length;
            if (nbNextSectionQuestions && nbNextSectionQuestions > 0) {
                break;
            }
        }

        if (nextSectionIndex >= globalEnvReducer.data.sections.length) {
            if (globalEnvReducer.data.meta.showResults) {
                navigate(`/${SECTION_INFORMATIONS}`);
                return;
            } else {
                navigate(`/${SECTION_THANK_YOU}`);
                return;
            }
        } else {
            // navigate to first question of next section
            navigate(`/${globalEnvReducer.data.sections[nextSectionIndex].slug}/${globalEnvReducer.data.sections[nextSectionIndex].description ? 0 : 1}?section=finished`);
            return;
        }
    } else {
        // find next question
        navigate(`/${sectionSlug}/${nextQuestionId}`);
        return;
    }
}

export function previousQuestions(sectionSlug: string | undefined, questionId: number | undefined, globalEnvReducer: IGlobalEnvStatus, navigate: NavigateFunction) {
    const currentSection = globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug);
    const prevQuestionId = questionId !== undefined ? questionId - 1 : 0;
    const indexQuestion = currentSection?.description ? prevQuestionId : prevQuestionId - 1;
    const currentSectionIndex = globalEnvReducer.data?.sections.findIndex((section) => section.slug === sectionSlug);
    const userRoles = getUserRoles(globalEnvReducer);

    if (globalEnvReducer.data && currentSection && currentSectionIndex !== undefined && indexQuestion < 0) {
        // find next section
        let prevSectionIndex = currentSectionIndex - 1;
        let nbPrevSectionQuestions = undefined;
        for (; prevSectionIndex > 0; prevSectionIndex--) {
            nbPrevSectionQuestions = filterQuestionsByRole(userRoles ? userRoles : [], globalEnvReducer.data?.sections[prevSectionIndex])?.length;
            if (nbPrevSectionQuestions && nbPrevSectionQuestions > 0) {
                break;
            }
        }

        if (prevSectionIndex < 0) {
            // end of questions
            navigate('/');
            return;
        } else {
            const newQuestionId = globalEnvReducer.data.sections[prevSectionIndex].slug === SECTION_WELCOME ? currentWelcomeMaxPage(globalEnvReducer.data?.meta.company ? true : false) : nbPrevSectionQuestions;

            navigate(`/${globalEnvReducer.data.sections[prevSectionIndex].slug}/${newQuestionId}`);
            return;
        }
    } else {
        if (sectionSlug === SECTION_WELCOME && indexQuestion === 0) {
            navigate('/');
            return;
        }

        // find prev question
        navigate(`/${sectionSlug}/${prevQuestionId}`);
        return;
    }
}

export function getCurrentQuestion(sectionSlug: string | undefined, questionId: number | undefined, globalEnvReducer: IGlobalEnvStatus) {
    const userRole = getUserRoles(globalEnvReducer);
    let questions = globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug)?.questions;

    if (userRole) {
        questions = filterQuestionsByRole(
            userRole,
            globalEnvReducer.data?.sections.find((section) => section.slug === sectionSlug)
        );
    }

    return questionId && questions ? questions[sectionSlug === SECTION_WELCOME ? 0 : questionId - 1] : undefined;
}

export function getUserRoles(globalEnvReducer: IGlobalEnvStatus) {
    const welcomeSection = globalEnvReducer.user?.sections?.find((section) => section.slug === SECTION_WELCOME);
    const maxQuestion = currentWelcomeMaxPage(globalEnvReducer.data?.meta?.company ? true : false);

    let useRoles = [0];

    if (welcomeSection) {
        const answerArrayFilteredByVerion = getAnswersArrayFilteredByVersion(welcomeSection.answersArray, globalEnvReducer);
        if (answerArrayFilteredByVerion.length >= maxQuestion) {
            const userRolesAnswer = answerArrayFilteredByVerion[maxQuestion - 1].find((answer) => answer.version === globalEnvReducer.data?.meta.version);

            if (userRolesAnswer) {
                useRoles = [...useRoles, ...userRolesAnswer.answers.map((answserIndex) => parseInt(answserIndex))];
            }
        }
    }

    return useRoles;
}

export function filterQuestionsByRole(roles: number[], section?: ISection) {
    if (!section) {
        return [];
    }
    //question?.example?.find((example) => example.scope.find((scope) => userRoles.includes(scope)) !== undefined);
    return section.questions.filter((question) => question.scope.find((scope) => roles.includes(scope)) !== undefined);
}
