import { Box, List, ListItemButton, Typography } from '@mui/material';
import React from 'react';
import { getBackendUrl } from '../../utils/url';
import { ICompanyResults, ISectionResult } from '../../types/companyResults';
import ResultView, { ICompareScores } from '../../components/result/ResultView';
import { useTranslation } from 'react-i18next';
import MultiRef from 'react-multi-ref';

import style from './CompanyResults.module.scss';

export interface IResultProps {}

const CompanyResults: React.FC<IResultProps> = () => {
    const { t } = useTranslation();
    const [results, setRestults] = React.useState<ICompanyResults | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [itemRefs] = React.useState(() => new MultiRef());
    const [compareScores, setCompareScores] = React.useState<Array<ICompareScores>>([]);

    // get all results.json
    React.useEffect(() => {
        try {
            fetch(`${getBackendUrl()}/companyResults`)
                .then((response) => response.json())
                .then((json?: ICompanyResults) => {
                    if (json) {
                        setRestults(json);
                        // add compare scores
                        const companyResult = json.departements[0];
                        const compareScoreGlobal: ICompareScores = {
                            name: companyResult.name,
                            totalScore: companyResult.score,
                            scorePerSection: companyResult.sections.map((section) => ({ slug: section.slug, score: section.score })),
                        };

                        setCompareScores([...compareScores, compareScoreGlobal]);
                    }
                });
        } catch (e) {
            setError(`${e}`);
        }
    }, []);

    return (
        <Box className={style.container}>
            {error && <Box className={style.error}>{error}</Box>}
            {results && (
                <>
                    <Box>
                        <List className={style.menu}>
                            {results.departements.map((departement, index) => (
                                <ListItemButton
                                    key={`${departement.name}-${index}`}
                                    onClick={() => {
                                        // @ts-ignore
                                        itemRefs?.map.get(index)?.scrollIntoView();
                                    }}
                                >
                                    <Typography>{departement.name}</Typography>
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>

                    <Box>
                        <Box className={style.titleContainer}>
                            <Typography variant="h2">{t('page.companyResults.titleLeft', { ns: 'common', value: results.companyName })}</Typography>
                            <Typography variant="h2">{t('page.companyResults.titleRight', { ns: 'common', value: results.companyName })}</Typography>
                        </Box>
                        {results.departements.map((departement, index) => {
                            const scorePerSection: Array<ISectionResult> = departement.sections.map((section) => ({ slug: section.slug, score: section.score, verbatim: section.verbatim, minScore: section.minScore, maxScore: section.maxScore }));
                            return (
                                <Box ref={itemRefs.ref(index)} key={index} className={style.resultView}>
                                    <ResultView title={departement.name} totalUsersCompleted={departement.completedSurvey} totalScore={departement.score} scorePerSection={scorePerSection} compareScores={index > 0 ? compareScores : undefined} />
                                    <hr className={style.hr} />
                                </Box>
                            );
                        })}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default CompanyResults;
