import { CircularProgress, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import style from './Circular.module.scss';
import { BsFillTriangleFill } from 'react-icons/bs';
import { ICompareScores } from '../../result/ResultView';

export interface ICircularProps {
    value: number;
    showIconDetails?: boolean;
    size?: number;
    compareScores?: ICompareScores[];
}

const Circular: React.FC<ICircularProps> = ({ value, showIconDetails, size = 100, compareScores }) => {
    const { t } = useTranslation();

    return (
        <Box className={style.container}>
            <CircularProgress className={style.background} variant="determinate" value={100} size={size} />

            <Box className={style.circularContainer}>
                <CircularProgress
                    className={style.progress}
                    variant="determinate"
                    value={value}
                    size={size}
                    sx={{
                        '& .MuiCircularProgress-circleDeterminate': {
                            color: `hsl(${value}, 90%, 50%)`,
                        },
                    }}
                />

                {compareScores &&
                    compareScores.map((compareScore, key) => {
                        const value = 26; //compareScore.totalScore;
                        const angle = (value / 100) * 2 * Math.PI; // Convertit le pourcentage en radians
                        const x = (size / 2) * Math.sin(angle);
                        const y = (-size / 2) * Math.cos(angle);

                        return (
                            <span key={key} className={`${style.compareCursor} ${style[`color${key > 6 ? Math.round(key / 6) : key}`]}`} style={{ left: `calc(50% + ${x}px - 8px)`, top: `calc(50% + ${y}px - 10px)` }}>
                                <BsFillTriangleFill
                                    style={{
                                        transform: ` scale(-1, -1) rotate(${angle}rad)`,
                                    }}
                                />
                            </span>
                        );
                    })}
            </Box>

            <Box className={style.valueContainer} width={size} height={size}>
                <Typography>{t('percent', { ns: 'common', value: value })}</Typography>
                {showIconDetails && (
                    <IconButton className={style.detailsCTA} size="small">
                        <AiOutlineQuestionCircle />
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};

export default Circular;
