import React from 'react';
import { useSearchParams } from 'react-router-dom';
import DefaultLayout from '../../layout/default/DefaultLayout';
import Home from '../home/Home';
import Init from '../init/Init';

export interface CustomeRouterProps {}

const CustomeRouter: React.FC<CustomeRouterProps> = () => {
    const [searchParams] = useSearchParams();
    const init = searchParams.get('init');

    if (init) {
        return <Init crypted={init} />;
    }

    return (
        <DefaultLayout>
            <Home />
        </DefaultLayout>
    );
};

export default CustomeRouter;
