import React, { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, TextField, Typography } from '@mui/material';

import style from './Open.module.scss';
import { IQuestion } from '../../../types/data';
import { useTranslation } from 'react-i18next';
import { IQuestionSingleForm } from '../single/Single';

export interface OpenProps extends PropsWithChildren {
    question: IQuestion;
    answer?: string[];
    callBack: (answer: string[]) => void;
}

const Open: React.FC<OpenProps> = ({ question, answer, callBack, children }) => {
    const { t } = useTranslation();
    const maxLength = parseInt(process.env.REACT_APP_MAX_OPEN_ANSWER_LENGTH || '0');

    const formOpenSchema = yup.object().shape({
        answer: yup.string().required(),
    });

    const {
        register: registerOpen,
        handleSubmit: handleSubmitOpen,
        formState: { errors: errorsOpen },
        reset: resetOpen,
        watch: watchOpen,
    } = useForm<IQuestionSingleForm>({
        defaultValues: { answer: answer ? answer[0] : '' },
        resolver: yupResolver(formOpenSchema),
    });

    const onSubmitOpenHandler = handleSubmitOpen((formData) => {
        callBack([formData.answer]);
    });

    React.useEffect(() => {
        resetOpen({ answer: answer ? answer[0] : '' });
    }, [question]);

    return (
        <form onSubmit={onSubmitOpenHandler} className={style.form}>
            <FormGroup>
                <TextField {...registerOpen('answer')} multiline minRows={3} inputProps={{ maxLength: maxLength }} helperText={`${watchOpen('answer')?.length}/${maxLength}`} error={watchOpen('answer')?.length > maxLength} />
                <Typography color="error">{errorsOpen.answer && t('error.oneAnswerNeeded', { ns: 'common' })}</Typography>
            </FormGroup>

            {children}
        </form>
    );
};

export default Open;
