import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NextCta from '../../components/cta/next/NextCta';
import PrevCta from '../../components/cta/prev/PrevCta';
import { useCustomUseParams } from '../../hooks/customUseParamsHooks';
import { useAppSelector } from '../../hooks/storesHooks';
import { SECTION_WELCOME } from '../../utils/welcome';

import style from './Company.module.scss';

export interface ICompanyProps {}

const Company: React.FC<ICompanyProps> = () => {
    const { t } = useTranslation();
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const { sectionSlug, questionId } = useCustomUseParams();

    if (sectionSlug === SECTION_WELCOME && globalEnvReducer.data?.meta.company && questionId === 2) {
        return (
            <Box className={style.container}>
                <Box className={style.content}>
                    <Box className={style.header}>
                        {globalEnvReducer.data?.meta.company.logo && <img className={style.logo} src={globalEnvReducer.data?.meta.company.logo} alt="logo" />}
                        <Typography variant="h3">{globalEnvReducer.data.meta.company.name}</Typography>
                    </Box>
                    <table className={style.table}>
                        <tbody>
                            {globalEnvReducer.data.meta.company.date && (
                                <tr>
                                    <td className={style.left}>
                                        <Typography>{t('page.company.creation', { ns: 'common' })}</Typography>
                                    </td>
                                    <td className={style.right}>
                                        <Typography>{globalEnvReducer.data.meta.company.date}</Typography>
                                    </td>
                                </tr>
                            )}
                            {globalEnvReducer.data.meta.company.revenue && (
                                <tr>
                                    <td className={style.left}>
                                        <Typography>{t('page.company.revenue', { ns: 'common' })}</Typography>
                                    </td>
                                    <td className={style.right}>
                                        <Typography>{globalEnvReducer.data.meta.company.revenue}</Typography>
                                    </td>
                                </tr>
                            )}
                            {globalEnvReducer.data.meta.company?.size > 0 && (
                                <tr>
                                    <td className={style.left}>
                                        <Typography>{t('page.company.people', { ns: 'common' })}</Typography>
                                    </td>
                                    <td className={style.right}>
                                        <Typography>{globalEnvReducer.data.meta.company.size}</Typography>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    <Box className={style.ctaContainer}>
                        <PrevCta />
                        <NextCta />
                    </Box>
                </Box>
            </Box>
        );
    }

    return null;
};

export default Company;
