import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './NotFound.module.scss';

export interface INotFoundProps {}

const NotFound: React.FC<INotFoundProps> = () => {
    const { t } = useTranslation();

    return (
        <Box className={style.container}>
            <Typography>{t('page.404.text', { ns: 'common' })}</Typography>
        </Box>
    );
};

export default NotFound;
