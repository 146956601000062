import React from 'react';

import style from './Robot.module.scss';
import { Box, LinearProgress } from '@mui/material';
import { useAppSelector } from '../../../hooks/storesHooks';
import { useCustomUseParams } from '../../../hooks/customUseParamsHooks';
import { filterQuestionsByRole, getUserRoles } from '../../../utils/nextPrevFlow';
import { SECTION_WELCOME, currentWelcomeMaxPage } from '../../../utils/welcome';
import { getAnswersArrayFilteredByVersion } from '../../../utils/checkAnswers';

export interface IRobotProps {}

const Robot: React.FC<IRobotProps> = () => {
    const globalEnvReducer = useAppSelector((state) => state.globalEnvStatusReducer);
    const [progress, setProgress] = React.useState(0);
    const [robotStatus, setRobotStatus] = React.useState('idle');
    const { questionId } = useCustomUseParams();
    const userRoles = getUserRoles(globalEnvReducer);

    React.useEffect(() => {
        const totalQuestion = globalEnvReducer.data?.sections.reduce((acc, section) => acc + (section.slug === SECTION_WELCOME ? currentWelcomeMaxPage(globalEnvReducer.data?.meta?.company ? true : false) : filterQuestionsByRole(userRoles, section)?.length), 0);

        const totalAnsweredQuestion = globalEnvReducer.user?.sections.reduce((acc, section) => acc + getAnswersArrayFilteredByVersion(section.answersArray, globalEnvReducer)?.length, 0);

        if (totalQuestion && totalAnsweredQuestion) {
            const percentProgress = (totalAnsweredQuestion * 100) / totalQuestion;
            setProgress(percentProgress);

            setRobotStatus('run');
            setTimeout(() => setRobotStatus('idle'), 1000);
            setTimeout(() => setRobotStatus('stop'), 5500);
        }
    }, [questionId]);

    return (
        <Box className={style.container}>
            <LinearProgress className={style.progressBar} variant="determinate" value={progress} />
            <img src="../images/robot/robot-idle.gif" alt="progress bar robot" className={style.progressBarRobot} style={{ left: `calc(${progress}% - 12px)`, display: robotStatus === 'idle' ? 'block' : 'none' }} />
            <img src="../images/robot/robot-run.gif" alt="progress bar robot" className={style.progressBarRobot} style={{ left: `calc(${progress}% - 12px)`, display: robotStatus === 'run' ? 'block' : 'none' }} />
            <img src="../images/robot/robot-preview.png" alt="progress bar robot" className={style.progressBarRobot} style={{ left: `calc(${progress}% - 12px)`, display: robotStatus === 'stop' ? 'block' : 'none' }} />
        </Box>
    );
};

export default Robot;
